// App.js
import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import axios from "axios";
import Logo from "./assets/logo_escura.png";

import UserPage from "./UserPage";
import Chat from "./Chat";
import NFE from "./NFE";

const App = () => {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    //const localDevToken = process.env.REACT_APP_LOCAL_DEV_TOKEN || null;
    const localDevToken ="31889a72-dc5d-428c-9aa5-f0064665bebc";
    const token = localDevToken || localStorage.getItem("access_token");

    if (localDevToken) {
      // Se o token local de desenvolvimento estiver presente, considera o usuário autenticado
      console.log("Token de desenvolvimento detectado. Usuário autenticado.");
    } else if (code) {
      // Se houver um código na URL, troca pelo token
      exchangeCodeForToken(code);
    } else if (token) {
      // Se já houver um token armazenado, valida
      validateToken(token);
    } else {
      // Caso contrário, redireciona para o OAuth
      redirectToOAuth();
    }
  }, []);

  const redirectToOAuth = () => {
    const authorizeUrl = `${process.env.REACT_APP_OAUTH_AUTHORIZE_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=read`;
    window.location.href = authorizeUrl;
  };

  const exchangeCodeForToken = async (code) => {
    const tokenUrl = process.env.REACT_APP_OAUTH_TOKEN_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

    const basicAuthToken = btoa(`${clientId}:${clientSecret}`);
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("redirect_uri", window.location.origin);

    try {
      const response = await axios.post(tokenUrl, params, {
        headers: {
          Authorization: `Basic ${basicAuthToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { access_token } = response.data;
      localStorage.setItem("access_token", access_token); // Armazena o token no localStorage
      window.history.replaceState({}, document.title, window.location.pathname); // Limpa o code da URL
      validateToken(access_token); // Valida o token obtido
    } catch (error) {
      console.error("Erro ao trocar o código por token:", error);
      redirectToOAuth();
    }
  };

  const validateToken = async (token) => {
    try {
      await axios.get(process.env.REACT_APP_USER_DATA_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Token inválido, redirecionando para login:", error);
      redirectToOAuth();
    }
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <img src={Logo} alt="Logo" className="logo" />
          <Link to="/chat">Chat</Link>
          <Link to="/user">User</Link>
          <Link to="/nfe">NFE</Link>
        </nav>
        <Routes>
          <Route path="/" element={<Navigate to="/user" />} />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nfe"
            element={
              <ProtectedRoute>
                <NFE />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

const ProtectedRoute = ({ children }) => {
  const token = process.env.REACT_APP_LOCAL_DEV_TOKEN || localStorage.getItem('access_token');
  return token ? children : <Navigate to="/" />;
};

export default App;
