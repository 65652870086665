// NFE.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NFE.css';


const NFE = () => {
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState('');
  const [selectedCidade, setSelectedCidade] = useState('');
  const [certificadoAtivo, setCertificadoAtivo] = useState(false);

  useEffect(() => {
    // Carregar lista de estados do Brasil usando a API do IBGE
    const fetchEstados = async () => {
      try {
        const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        setEstados(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };
    fetchEstados();
  }, []);

  const handleEstadoChange = async (e) => {
    const uf = e.target.value;
    setSelectedEstado(uf);
    setSelectedCidade('');
    try {
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
      setCidades(response.data);
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    }
  };

  return (
    <div>
      <h2>Formulário NFE</h2>
      <form  className="form-container">
      <div className="form-group">
        <label>Estado:</label>
        <select value={selectedEstado} onChange={handleEstadoChange}>
          <option value="">Selecione o Estado</option>
          {estados.map((estado) => (
            <option key={estado.id} value={estado.sigla}>
              {estado.nome}
            </option>
          ))}
        </select>
        </div>

        <div className="form-group">
        <label>Cidade:</label>
        <select value={selectedCidade} onChange={(e) => setSelectedCidade(e.target.value)} disabled={!selectedEstado}>
          <option value="">Selecione a Cidade</option>
          {cidades.map((cidade) => (
            <option key={cidade.id} value={cidade.nome}>
              {cidade.nome}
            </option>
          ))}
        </select>
        </div>

        <div className="form-group">
        <label>Inscrição Municipal:</label>
        <input type="text" />
        </div>

        <div className="form-group">
        <label>Regime Fiscal da Sociedade:</label>
        <select>
        <option></option>
          <option></option>
          <option>Simples Nacional - Exceção</option>
          <option>Regime Normal - Lucro Presumido</option>
          <option>Normal - Lucro Real</option>
        </select>
        </div>

        <div className="form-group">
        <label>Possui Registro Tributário Especial:</label>
        <select>
          <option>Sem Regime Tributário Especial</option>
          <option>Micro Empresa Municipal</option>
          <option>Estimativa</option>
          <option>Sociedade de Profissionais X</option>
          <option>Cooperativa</option>
          <option>Microempresário Individual - MEI</option>
          <option>Microempresa ou Pequeno Porte - ME EPP</option>
          <option>Lucro Real</option>
          <option>Lucro Presumido</option>
          <option>Tributação Normal</option>
          <option>Simples nacional com excesso do sublimite</option>
          <option>Empresa de Responsabilidade Limitada</option>
        </select>
        </div>


        <div className="form-group">
        <label>CNAE Padrão:</label>
        <input type="text" />
        </div>

        <div className="form-group">
        <label>Aliquota ISS:</label>
        <input type="number" />
        </div>

        <div className="form-group">
        <label>Outras Informações:</label>
        <textarea />
        </div>

        <div className="checkbox-group">
        <label>
          <input type="checkbox" checked={certificadoAtivo} onChange={() => setCertificadoAtivo(!certificadoAtivo)} />
          Atualizar Certificado Digital
        </label>
        </div>

        {/* Campos Condicionais para Certificado Digital */}
        {certificadoAtivo && (
          <>
           <div className="form-group">
            <label>Certificado Digital:</label>
            <input type="file" />
            </div>

            <div className="form-group">
            <label>Senha Certificado:</label>
            <input type="password" />
            </div>
          </>
        )}

        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default NFE;