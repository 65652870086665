import React, { useState } from 'react';
import axios from 'axios';
import './Chat.css';

const Chat = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');


  const sendQuestion = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(
        'http://localhost:8000/chats',
        { question },
        {
          headers: {
            'access_token': `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setAnswer(response.data.answer);
    } catch (error) {
      console.error('Error:', error);
      setAnswer('Houve um erro ao processar sua pergunta.');
    }
  };

  return (
    <div className="container">
      <div className="logo">
        <img src="assets/logo.png" alt="Logo" />
      </div>
      <div className="chat-window">
        <div className="question">
          <input
            type="text"
            x-webkit-speech
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Faça sua pergunta..."
          />
          <button onClick={sendQuestion}>Enviar</button>
        </div>
        <div className="answer">
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default Chat;
